import React from 'react';
import Social from './Social';
import img from './TwitchGlitchPurple.svg';

const TwitchSocials: React.FC = () => (
  <Social
    message="Follow us on Twitch"
    img={img}
    alt="twitch-logo"
    link="https://www.twitch.tv/goonsrecords"
  />
);

export default TwitchSocials;
