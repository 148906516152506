import React from 'react';
import './AboutPage.scss';
import Player from '../../components/Player';
import Socials from '../../components/Socials';

const AboutPage: React.FC = () => (
  <div className="about-page">
    <div className="about-text">
      <h2>Keepin' Beans Cool Since 2020</h2>
      <p>We make music and stream live DJ sets sometimes.
      Follow us on Spotify and Soundcloud to listen to some of our playlists. If you like the music we're playing, follow us on Twitch to watch our live sets and join in on the fun!
      You can also reach out to support@goonsrecords.com with any questions or comments.</p>
    </div>
    <div className="player">
      <h2>Featured Playlist</h2>
      <Player playlistId="0qNKSkivPhp63vOoHa6leX" playlistTitle="Morning Coffee"/>
    </div>

    <div className="socials">
      <Socials />
    </div>
  </div>
);

export default AboutPage;
