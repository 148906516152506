import React from 'react';
import './Player.scss';

type PlayerProps = {
  playlistId: string;
  playlistTitle: string;
};

const Player: React.FC<PlayerProps> = ({ playlistId, playlistTitle }: PlayerProps) => (
  <div className="player">
    <iframe src={getEmbedPlayerURL(playlistId)} title={playlistTitle} width="300" height="380" allow="encrypted-media"></iframe>
  </div>
);

function getEmbedPlayerURL(playlistId: string) {
  return "https://open.spotify.com/embed/playlist/" + playlistId;
}

export default Player;
