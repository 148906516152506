import React from 'react';
import './Socials.scss';

type SocialsProps = {
  message: string;
  img: string;
  alt: string;
  link: string;
};

const Social: React.FC<SocialsProps> = ({ message, img, alt, link }: SocialsProps) => (
  <div className="social">
    <p>{message}</p>
    <a href={link} target="_blank" rel="noopener noreferrer">
      <img alt={alt} src={img} />
    </a>
  </div>
);

export default Social;
