import React from 'react';
import './NotFoundPage.scss';
import img from './PartyBeans.svg';

const NotFoundPage: React.FC = () => (
  <div className="not-found-page">
    <h1>We can't find the page you're looking for</h1>
    <p>Hope you enjoy these partying beans though!</p>
    <img alt='party beans' src={img}/>
  </div>
);

export default NotFoundPage;
