import React from 'react';
import './LandingPage.scss';
import Logo from '../../components/Logo';
import Socials from '../../components/Socials';

const LandingPage: React.FC = () => (
  <div className="landing-page">
    <Logo />
    <Socials />
  </div>
);

export default LandingPage;
