import React from 'react';
import './Grid.scss';

type GridElementProps = {
  message: string;
  img: string;
  alt: string;
};

const GridElement: React.FC<GridElementProps> = ({ message, img, alt }: GridElementProps) => (
  <div className="grid-element">
    <img alt={alt} src={img} />
    <p>{message}</p>
  </div>
);

export default GridElement;
