import React from 'react';
import Social from './Social';
import img from './SpotifySocial.png';

const SpotifySocials: React.FC = () => (
  <Social
    message="Check out our Spotify"
    img={img}
    alt="spotify-logo"
    link="https://open.spotify.com/user/g68qw0y6cjag8hotau83ahwhj?si=MEUyGtozR26i6cfqvgwvTA"
  />
);

export default SpotifySocials;
