import React from 'react';
import './Logo.scss';
import legumes from './beans.svg';

const Logo: React.FC = () => (
  <div className="logo">
    <img alt="bean" src={legumes} />
  </div>
);

export default Logo;
