import React from 'react';

const IFRAME_URL =
  'https://w.soundcloud.com/icon/?url=http%3A%2F%2Fsoundcloud.com%2Foonsecords&color=orange_white&size=64';

const SoundcloudSocials: React.FC = () => (
  <div className="social">
    <p>Listen on Soundcloud</p>
    <iframe title="Soundcloud goonsrecords" frameBorder="no" src={IFRAME_URL}></iframe>
  </div>
);

export default SoundcloudSocials;
