import React from 'react';
import SoundcloudSocials from './SoundcloudSocials';
import SpotifySocials from './SpotifySocials';
import TwitchSocials from './TwitchSocials';
import './Socials.scss';

const Socials: React.FC = () => (
  <div className="socials">
    <SpotifySocials />
    <TwitchSocials />
    <SoundcloudSocials />
  </div>
);

export default Socials;
