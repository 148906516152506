import React from 'react';
import { Link } from 'react-router-dom';
import './Header.scss';

type NavElementProps = {
  path: string;
  message: string;
};

const NavElement: React.FC<NavElementProps> = ({ path, message }: NavElementProps) => (
  <Link className="nav-element" to={path}>{message}</Link>
)

const Title: React.FC = () => (
  <div className="title">Goons Records</div>
)

const Header: React.FC = () => (
  <div className="header">
    <Title />
    <div className="nav">
      <NavElement path="/" message="Home" />
      <NavElement path="/about" message="About" />
      <NavElement path="/merch" message="Merch" />
    </div>
  </div>
);

export default Header;
