import React from 'react';
import { GridElement } from '../../components/Grid';
import coolerShirt from './cooler.png';
import dancingBeanShirt from './dancingbean.png';
import partyBeansShirt from './party-beans-shirt.png';
import './MerchPage.scss';

const MerchGrid: React.FC = () => (
  <div className="merch-grid">
    <GridElement message="Bean Cooler" img={coolerShirt} alt="bean-cooler-merch"/>
    <GridElement message="Dancing Bean" img={dancingBeanShirt} alt="dancing-bean-merch"/>
    <GridElement message="Party Beans" img={partyBeansShirt} alt="party-beans-merch"/>
  </div>
 )

const MerchPage: React.FC = () => (
  <div className="merch-page">
    <p>Reach out at support@goonsrecords to inquire about purchasing merch</p>
    <MerchGrid />
  </div>
);

export default MerchPage;
