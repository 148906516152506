import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.scss';
import Header from './components/Header';
import AboutPage from './pages/AboutPage';
import LandingPage from './pages/LandingPage';
import MerchPage from './pages/MerchPage';
import NotFoundPage from './pages/NotFoundPage';

const App: React.FC = () => (
  <div className="App">
    <Router>
      <Header />
      <Switch>
        <Route exact path="/">
          <LandingPage />
        </Route>
        <Route exact path="/about">
          <AboutPage />
        </Route>
        <Route exact path="/merch">
          <MerchPage />
        </Route>
        <Route path="*">
            <NotFoundPage />
          </Route>
      </Switch>
    </Router>
  </div>
);

export default App;
